<template>
  <div class="container">
    <SimpleHeader />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="tabs" v-if="tabs.length>1">
        <van-tabs v-model:active="tabIndex" background="transparent" color="#DABA88" @click-tab="onClickTab">
          <van-tab v-for="(tab,index) in tabs" :title="tab.name"></van-tab>
        </van-tabs>
      </div>
      <div class="container-body">
        <div class="card-it" v-for="(item,index) in list" :key="index" >
          <div class="card-head">
            <span class="card-title">{{item.title}}</span>
            <span>完成度{{item.progress}}%</span>
          </div>
          <div class="card-body">
            <span class="card-txt"><span class="card-txt2">{{item.now}}</span>/{{item.target}}</span>
            <van-circle
                v-model:current-rate="item.currentRate"
                :rate="item.progress"
                layer-color="#ebedf0"
                color="#85D178"
                size="60px"
                :speed="100"
                :stroke-width="100"
              />
          </div>
        </div>
        <Empty :image="true" :show="!loading&&list.length==0"></Empty>
      </div>
    </van-pull-refresh>
  </div>
</template>
<script>
import SimpleHeader from '@/components/SimpleHeader'
import { onMounted,reactive,toRefs, inject } from 'vue'
import { useRouter } from 'vue-router'
import { Toast, PullRefresh } from 'vant'
import { getSituationData } from '@/api/statis'
import usePage from '@/hooks/usePage'
import to from 'await-to-js';
export default {
  name: 'statis',
  components: {
    [PullRefresh.name]: PullRefresh,
    SimpleHeader
  },
  setup() {
    const _appLoadFanish = inject('appLoadFanish');
    const { navigateTo } = usePage()
    const state = reactive({
      tabs:[],
      tabIndex: 0,
      list:[],
      refreshing: false,
      loading: false,
    })
    const toPage = url => {
      navigateTo(url)
    }
    const tips = () => {
      Toast('敬请期待')
    }
    const initData = () => {
      state.list = []
    }
    const getData = async ()=> {
      if (state.refreshing) {
        initData()
        state.refreshing = false
      }
      let params = {
        type:  state.list[state.tabIndex]?.type || 1
      }
      let [error,result] = await to(getSituationData(params))
      _appLoadFanish()
      state.loading = false
      if(error) return;
      let {code, res} = result;
      if (code === 0) {
        state.tabs = res.tabs
        state.list = res.list && res.list.map(v=>{
          v.currentRate = 0
          return v
        })
      }
    }
    //刷新页面
    const onRefresh = () => {
      state.loading = true
      getData()
    }

    const onClickTab = () => {
      initData()
      getData()
    }

    onMounted(async () => {
      getData()
    })
    return {
     ...toRefs(state),
     onRefresh,
     onClickTab
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../common/style/searchpicker';

.container {
  background: #f8f8f8;
  min-height: 100%;
  &-body{
    padding: 15px;
  }
  .tabs{
   margin-bottom: 8px;
   background: #ffffff;
  }
  .card-it {
    background: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    padding: 10px;
    .card-head{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 34px;
    }
    .card-body{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;
      // height: 34px;
    }
    // .card-title{
    // font-size: 15px;
    // }
    // .card-txt{
    //   font-size: 14px;
    // }
    .card-txt2{
      font-size: 18px;
    }
    +.card-it{
      margin-top: 15px;
    }

  }
}

</style>
